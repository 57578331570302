import React from 'react';

export const IconAi = () => {
  return (
    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_119_56)">
        <rect x="4" y="3" width="18" height="16" rx="4" fill="url(#paint0_linear_119_56)" />
      </g>
      <path
        d="M11.4948 6.00201H6.7126V7.83218H11.0596C11.4254 7.83218 11.7197 8.11313 11.7197 8.46231V9.54396H8.53507C7.13511 9.54396 6 10.6276 6 11.9641V13.0277C6 14.3642 7.13511 15.4479 8.53507 15.4479H14.0298V8.42017C14.0298 7.08366 12.8947 6 11.4948 6V6.00201ZM11.7176 13.6217H8.9702C8.60444 13.6217 8.31015 13.3408 8.31015 12.9916V12.0043C8.31015 11.6551 8.60444 11.3741 8.9702 11.3741H11.7176V13.6237V13.6217Z"
        fill="white"
      />
      <path d="M18.0001 6.00211H15.6899V15.45H18.0001V6.00211Z" fill="white" />
      <defs>
        <filter
          id="filter0_d_119_56"
          x="0"
          y="0"
          width="26"
          height="24"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.164706 0 0 0 0 0 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_119_56" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_119_56"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_119_56"
          x1="6.5"
          y1="7.57143"
          x2="21.1271"
          y2="15.6031"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF2A00" />
          <stop offset="1" stop-color="#002BFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
