import { createRef, RefObject, useRef } from 'react';

function useDynamicRefs() {
  const mapRef = useRef(new Map<string, RefObject<unknown>>());

  function setRef<ST>(key: string) {
    if (!key) {
      console.warn(`useDynamicRefs: Cannot set ref without key `);
      return;
    }
    const ref = createRef<ST>();
    mapRef.current.set(key, ref);
    return ref;
  }

  function getRef<GT>(key: string) {
    if (!key) {
      console.warn(`useDynamicRefs: Cannot get ref without key`);
      return;
    }
    return mapRef.current.get(key) as RefObject<GT>;
  }

  return {
    getRef,
    setRef,
  };
}

export default useDynamicRefs;
