import { CloseOutlined } from '@ant-design/icons';
import {
  Brand,
  OmniaVideo,
  OmniaVideoItem,
  Product,
  ProductFamily,
  Species,
  TechniqueFull,
  Waterbody,
} from '@omniafishing/core';
import classNames from 'classnames';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import utc from 'dayjs/plugin/utc';
import { PlyrEvent, Source } from 'plyr';
import React, { CSSProperties, RefObject, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { isDone, isPending, LoadingState } from '../../constants/loading_state';
import { useDimensions } from '../../hooks/use_dimensions';
import useDynamicRefs from '../../hooks/use_dynamic_refs';
import { useListAttributionUuid } from '../../hooks/use_list_attribution_uuid';
import { useListViewed } from '../../hooks/use_list_viewed';
import { useQueryString } from '../../hooks/use_query_string';
import { useResponsive } from '../../hooks/use_responsive';
import { useUser } from '../../hooks/use_user';
import { getImgixPath } from '../../lib/imgix';
import { userIsOmniaVideoAffiliateMatch } from '../../lib/user';
import { WebAnalytics, WebAnalyticsEventAreas } from '../../lib/web_analytics';
import { InventoryModalActions } from '../../redux/inventory_modal';
import { isUserAdmin } from '../../redux/user';
import { RoutePaths } from '../../routes';
import Loading from '../loading/loading';
import { OmniaLinkButton } from '../omnia_button/omnia_link_button';
import { GetVideoSchema } from '../omnia_video_page_component/get_video_schema';
import {
  BrandModal,
  SpeciesModal,
  TechniqueModal,
  WaterbodyModal,
} from '../omnia_video_page_component/omnia_video_modals';
import {
  useOmniaVideoAddToCart,
  webAnalyticsVideoItemClick,
} from '../omnia_video_page_component/use_omnia_video_add_to_cart';
import { useOmniaVideoOpenProductFamilyModal } from '../omnia_video_page_component/use_omnia_video_open_product_family_modal';
import { ProTrialButton } from '../pro_buttons/pro_trial_button';
import { PublishedDates } from '../published_dates/published_dates';
import { VideoItemSidebarAmbassador } from '../video_items/video_item_sidebar_ambassador';
import { VideoItemSidebarBrand } from '../video_items/video_item_sidebar_brand';
import { VideoItemSidebarCategory } from '../video_items/video_item_sidebar_category';
import { VideoItemSidebarOmniaVideoContent } from '../video_items/video_item_sidebar_omnia_video_content';
import { VideoItemSidebarProduct } from '../video_items/video_item_sidebar_product';
import sidebarStyles from '../video_items/video_item_sidebar_product.less';
import { VideoItemSidebarProductFamily } from '../video_items/video_item_sidebar_product_family';
import { VideoItemSidebarSpecies } from '../video_items/video_item_sidebar_species';
import { VideoItemSidebarTechnique } from '../video_items/video_item_sidebar_technique';
import { VideoItemSidebarWaterbody } from '../video_items/video_item_sidebar_waterbody';
import { LoadableVideoPlayer } from '../video_player/loadable_video_player';
import { HTMLPlyrVideoElement } from '../video_player/video_player';
import styles from './omnia_video.less';

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

export enum OmniaVideoQueryParams {
  time = 't',
}

interface OmniaVideoProps {
  className?: string;
  isDockable?: boolean;
  omnia_video_items: OmniaVideoItem[];
  omnia_video: OmniaVideo;
  omniaVideoItemsLoadingState?: LoadingState;
  onCurrentVideoItemChange?: (videoItemId: number) => void;
  onVideoLoaded?: () => void;
  openCartOnAdd?: boolean;
  shortMobile?: boolean;
  publishDatesClassName?: string;
  showVideoInfo?: boolean;
  showVideoSummary?: boolean;
  timeToSeekTo?: number;
  verticalLayout?: boolean;
  videoScrollRef?: RefObject<HTMLDivElement>;
}

export const OmniaVideoComponent = (props: OmniaVideoProps) => {
  const {
    className,
    isDockable = false,
    omnia_video_items,
    omnia_video,
    omniaVideoItemsLoadingState,
    onCurrentVideoItemChange,
    onVideoLoaded,
    openCartOnAdd,
    publishDatesClassName,
    shortMobile,
    showVideoInfo = true,
    showVideoSummary = true,
    timeToSeekTo,
    verticalLayout,
    videoScrollRef,
  } = props;

  const [currentVideoItemId, setCurrentVideoItemId] = useState(null as number);
  const { getRef, setRef } = useDynamicRefs();
  const productsRef = useRef<HTMLUListElement>();
  const proCtasRef = useRef<HTMLLIElement>();
  const [measureRef, bounds] = useDimensions();
  const { ref, inView } = useInView({
    initialInView: true,
    skip: !isDockable, // skips ref from being set internally
  });
  const dispatch = useDispatch();
  const [videoRef, setVideoRef] = useState(null as HTMLPlyrVideoElement);
  const plyr = videoRef?.plyr;
  const { user, isPro } = useUser();
  const isAdmin = useSelector(isUserAdmin);
  const isAdminOrAffiliateMatch = user?.admin || userIsOmniaVideoAffiliateMatch(user, omnia_video);
  const showEditLink = isAdmin || isAdminOrAffiliateMatch;
  const { getCurrentQuery } = useQueryString();
  const currentQuery = getCurrentQuery<{ [OmniaVideoQueryParams.time]: number }>();

  const [brandModalOpen, setBrandModalOpen] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(null as Brand);
  const [selectedSpecies, setSelectedSpecies] = useState(null as Species);
  const [selectedTechnique, setSelectedTechnique] = useState(null as TechniqueFull);
  const [selectedWaterbody, setSelectedWaterbody] = useState(null as Waterbody);
  const [speciesModalOpen, setSpeciesModalOpen] = useState(false);
  const [techniqueModalOpen, setTechniqueModalOpen] = useState(false);
  const [userUndocked, setUserUndocked] = useState(false);
  const [videoDocked, setVideoDocked] = useState(false);
  const [waterbodyModalOpen, setWaterbodyModalOpen] = useState(false);

  const { coupon, createListId } = useListViewed();
  const list_uuid = useListAttributionUuid();
  const { isDesktop } = useResponsive();

  const {
    title,
    mux_playback_id,
    slug,
    publication_date,
    thumbnail,
    summary,
    source,
    updated_at,
    tags,
  } = omnia_video;

  const showProCtas = tags.includes('pro') && !isPro;

  const videoStyles: CSSProperties = videoDocked
    ? {
        bottom: 10,
        left: 'auto',
        position: 'fixed',
        right: 10,
        top: 'auto',
        transform: 'scale(0.7)',
        width: bounds ? bounds.width : 'auto',
        zIndex: 7,
      }
    : {};

  useEffect(() => {
    if (timeToSeekTo && plyr) {
      plyr.currentTime = timeToSeekTo;
      plyr.play();
    }
  }, [timeToSeekTo, plyr]);

  const { omniaVideoAddToCart } = useOmniaVideoAddToCart();
  const { omniaVideoOpenProductFamilyModal } = useOmniaVideoOpenProductFamilyModal();

  const openBrandModal = (b: Brand, position: number, omnia_video_item: OmniaVideoItem) => () => {
    setBrandModalOpen(true);
    setSelectedBrand(b);
    webAnalyticsVideoItemClick(omnia_video_item, position, omnia_video);
  };
  const openSpeciesModal =
    (species: Species, position: number, omnia_video_item: OmniaVideoItem) => () => {
      setSpeciesModalOpen(true);
      setSelectedSpecies(species);
      webAnalyticsVideoItemClick(omnia_video_item, position, omnia_video);
    };
  const openTechniqueModal =
    (technique: TechniqueFull, position: number, omnia_video_item: OmniaVideoItem) => () => {
      setTechniqueModalOpen(true);
      setSelectedTechnique(technique);
      webAnalyticsVideoItemClick(omnia_video_item, position, omnia_video);
    };
  const openWaterbodyModal =
    (waterbody: Waterbody, position: number, omnia_video_item: OmniaVideoItem) => () => {
      setWaterbodyModalOpen(true);
      setSelectedWaterbody(waterbody);
      webAnalyticsVideoItemClick(omnia_video_item, position, omnia_video);
    };

  const onAmbassadorClick = (omnia_video_item: OmniaVideoItem, position: number) => () => {
    webAnalyticsVideoItemClick(omnia_video_item, position, omnia_video);
  };

  const scrollProductsToRef = (currentRef: RefObject<HTMLLIElement>) => {
    const top = currentRef.current.offsetTop;
    const scrollPosition = showProCtas ? top - (proCtasRef.current.offsetHeight - 1) : top; // -1 for border maybe?
    productsRef.current.scrollTo({
      top: scrollPosition,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (currentVideoItemId) {
      const currentRef = getRef<HTMLLIElement>(currentVideoItemId.toString());
      if (currentRef) {
        scrollProductsToRef(currentRef);
      }
    }
  }, [currentVideoItemId]);

  useEffect(() => {
    if (!userUndocked) {
      setVideoDocked(!inView);
    }
  }, [inView]);

  useEffect(() => {
    if (userUndocked) {
      setVideoDocked(false);
    }
  }, [userUndocked]);

  useEffect(() => {
    // video player loaded
    if (plyr) {
      plyr.on('timeupdate', onTimeUpdate as any);
      plyr.once('canplay', () => {
        onVideoLoaded?.();
        const timeToSeek = currentQuery.t;
        if (timeToSeek) {
          // using seek() here didn't work
          plyr.currentTime = Number(timeToSeek);
        }
      });
    }
  }, [plyr]);

  useEffect(() => {
    if (omnia_video_items.length) {
      const productFamilyVideoItems = omnia_video_items.filter(
        (i) => i.item_type === 'ProductFamily'
      );
      const productVideoItems = omnia_video_items.filter((i) => i.item_type === 'Product');
      WebAnalytics.productListViewed({
        coupon,
        list_id: createListId('video', `${omnia_video.id}`),
        products: productVideoItems.map((i) => i.item) as Product[],
        list_uuid,
      });
      WebAnalytics.productFamilyListViewed({
        coupon,
        list_id: createListId('video', `${omnia_video.id}`),
        productFamilies: productFamilyVideoItems.map((i) => i.item) as ProductFamily[],
        list_uuid,
      });
    }
  }, [omnia_video_items.map((i) => i.id).join()]);

  useEffect(() => {
    if (omnia_video) {
      WebAnalytics.omniaVideoPageView({
        video_pub_date: publication_date,
        video_title: title,
        video_slug: slug,
      });
    }
  }, [omnia_video?.slug]);

  useEffect(() => {
    if (!plyr) {
      return;
    }

    plyr.on('play', (e) => {
      onPlayPause(e);
      setUserUndocked(false);
    });
    plyr.on('pause', (e) => onPlayPause(e));
    plyr.on('ended', (e) => onPlayPause(e));
  }, [plyr]);

  const onTimeUpdate = (event: PlyrEvent) => {
    const sec = Math.floor(event.detail.plyr.currentTime);
    const currentVideoItem = omnia_video_items.find((p) => sec >= p.start_sec && sec <= p.end_sec);

    if (currentVideoItem && currentVideoItem.id !== currentVideoItemId) {
      setCurrentVideoItemId(currentVideoItem.id);
      onCurrentVideoItemChange?.(currentVideoItem.id);
    }
    if (!currentVideoItem) {
      setCurrentVideoItemId(null);
      onCurrentVideoItemChange?.(null);
    }
  };

  const onPlayPause = (event: PlyrEvent) => {
    if (!plyr) {
      return;
    }

    const playbackStatus = event.type === 'ended' ? 'completed' : event.type;

    if (playbackStatus === 'pause' && plyr.duration === event.detail.plyr.currentTime) {
      return;
    }
    WebAnalytics.videoShoppablePlaybackStatus({
      video_title: title,
      video_pub_date: publication_date,
      video_slug: slug,
      status: playbackStatus,
    });
  };

  const seek = (time: number) => () => {
    if (!plyr) {
      return;
    }

    plyr.currentTime = time;
    plyr.play();
  };

  const getVideoItem = (item: OmniaVideoItem, selected: boolean, position: number) => {
    switch (item.item_type) {
      case 'ProductFamily':
        return (
          <VideoItemSidebarProductFamily
            onJumpClick={seek(item.start_sec)}
            omnia_video_item={item}
            onClick={omniaVideoOpenProductFamilyModal(
              item.item,
              position,
              item,
              omnia_video,
              list_uuid
            )}
            selected={selected}
            showJump
          />
        );
      case 'Product':
        return (
          <VideoItemSidebarProduct
            onJumpClick={seek(item.start_sec)}
            omnia_video_item={item}
            omnia_video={omnia_video}
            onAddToCartClick={omniaVideoAddToCart(
              item.item,
              position,
              item,
              omnia_video,
              list_uuid,
              openCartOnAdd
            )}
            onClick={() => {
              WebAnalytics.productClick({
                productOrProductFamily: item.item,
                show_modal: false,
                position,
                list_uuid,
              });
              webAnalyticsVideoItemClick(item, position, omnia_video);
            }}
            onOutOfStockClick={() =>
              dispatch(
                InventoryModalActions.INVENTORY_MODAL_OPEN(
                  item.item,
                  WebAnalyticsEventAreas.QUICK_VIEW
                )
              )
            }
            selected={selected}
            showJump
          />
        );
      case 'Species':
        return (
          <VideoItemSidebarSpecies
            onJumpClick={seek(item.start_sec)}
            omnia_video_item={item}
            onClick={openSpeciesModal(item.item, position, item)}
            selected={selected}
            showJump
          />
        );
      case 'Waterbody':
        return (
          <VideoItemSidebarWaterbody
            onJumpClick={seek(item.start_sec)}
            omnia_video_item={item}
            onClick={openWaterbodyModal(item.item, position, item)}
            selected={selected}
            showJump
          />
        );
      case 'Technique':
        return (
          <VideoItemSidebarTechnique
            onJumpClick={seek(item.start_sec)}
            omnia_video_item={item}
            onClick={openTechniqueModal(item.item, position, item)}
            selected={selected}
            showJump
          />
        );

      case 'User':
        return (
          <VideoItemSidebarAmbassador
            onJumpClick={seek(item.start_sec)}
            omnia_video_item={item}
            selected={selected}
            showJump
            onClick={onAmbassadorClick(item, position)}
          />
        );
      case 'Brand':
        return (
          <VideoItemSidebarBrand
            onJumpClick={seek(item.start_sec)}
            omnia_video_item={item}
            onClick={openBrandModal(item.item, position, item)}
            selected={selected}
            showJump
          />
        );

      case 'Category':
      case 'Subcategory':
        return (
          <VideoItemSidebarCategory
            onJumpClick={seek(item.start_sec)}
            omnia_video_item={item}
            onClick={() => {
              webAnalyticsVideoItemClick(item, position, omnia_video);
            }}
            selected={selected}
            showJump
          />
        );
      case 'OmniaVideoContent': {
        const urlIsPremium =
          item.item.url === `http://localhost:8080${RoutePaths.PREMIUM}` ||
          item.item.url === `https://stage.omniafishing.com${RoutePaths.PREMIUM}` ||
          item.item.url === `https://www.omniafishing.com${RoutePaths.PREMIUM}`;

        return urlIsPremium ? null : (
          // leaving if premium makes a comeback 03/2024
          // <VideoItemSidebarPremium
          //   onJumpClick={seek(item.start_sec)}
          //   omnia_video_item={item}
          //   onClick={() => {
          //     webAnalyticsVideoItemClick(item, position, omnia_video);
          //   }}
          //   selected={selected}
          //   showJump
          // />
          <VideoItemSidebarOmniaVideoContent
            onJumpClick={seek(item.start_sec)}
            omnia_video_item={item}
            onClick={() => {
              webAnalyticsVideoItemClick(item, position, omnia_video);
            }}
            selected={selected}
            showJump
          />
        );
      }
      default:
        break;
    }
  };

  const isYoutube = source?.includes('youtube.com') || false;

  const videoSource = isYoutube
    ? {
        type: 'video' as Plyr.MediaType,
        sources: [
          {
            src: source,
            provider: 'youtube',
          } as Source,
        ],
      }
    : {
        type: 'video' as Plyr.MediaType,
        poster: getImgixPath(thumbnail, { w: 1200 }),
        sources: [
          {
            src: 'currently the muxid creates the source internally',
          } as Source,
        ],
      };

  const hasNoVideoItems = omnia_video_items.length === 0 && isDone(omniaVideoItemsLoadingState);

  const VideoItemsList = (
    <ul className={styles.productList} ref={productsRef}>
      {showProCtas && (
        <li className={classNames(styles.product, styles.proCtaItem)} ref={proCtasRef}>
          <div className={classNames(sidebarStyles.productSidebar)}>
            <div className={styles.proCtaButtons}>
              <ProTrialButton
                size="lg"
                kind="primary"
                text="Start a 7-Day Trial for Pro"
                onClick={() => {
                  WebAnalytics.proButtonClicked({
                    location: 'omnia_video',
                    type: 'trial',
                    style: 'button',
                  });
                }}
              />
              <OmniaLinkButton
                to={RoutePaths.PREMIUM_PRO}
                size="lg"
                kind="secondary"
                onClick={() =>
                  WebAnalytics.proButtonClicked({
                    location: 'omnia_video',
                    type: 'learn_more',
                    style: 'button',
                  })
                }
              >
                Learn More
              </OmniaLinkButton>
            </div>
          </div>
        </li>
      )}
      {omniaVideoItemsLoadingState && isPending(omniaVideoItemsLoadingState) ? (
        <Loading text="Loading Video Items" style={{ height: '100%' }} />
      ) : (
        omnia_video_items.map((vi, i) => {
          const selected = currentVideoItemId === vi.id;
          const item = getVideoItem(vi, selected, i);

          return (
            <li className={styles.product} ref={setRef(vi.id.toString())} key={vi.id}>
              {item}
            </li>
          );
        })
      )}
    </ul>
  );

  const VideoInfo = (
    <>
      {showVideoInfo && (
        <>
          <p className={styles.title}>
            {title}{' '}
            {showEditLink && <Link to={`${RoutePaths.OMNIA_VIDEOS}/${slug}/edit`}>Edit</Link>}
          </p>
          <PublishedDates
            firstPublishedDate={publication_date}
            lastUpdatedDate={updated_at}
            className={publishDatesClassName}
          />
          {showVideoSummary && <p className={classNames(styles.videoSummary, 'mb-4')}>{summary}</p>}
        </>
      )}
    </>
  );

  const VerticalProductsList = (
    <>
      <div
        className={classNames(styles.products__mobile, {
          [styles.products__shortMobile]: shortMobile,
          [styles.products__mobile__proCtas]: showProCtas,
          [styles.products__mobile__noProducts]: hasNoVideoItems,
        })}
      >
        {VideoItemsList}
      </div>
      {VideoInfo}
    </>
  );

  const standardLayout = !verticalLayout;

  return (
    <>
      <section className={className}>
        <div className={styles.wrapper}>
          <div
            className={classNames(styles.video, {
              [styles.video__noItems]: hasNoVideoItems,
            })}
            ref={videoScrollRef}
          >
            <div ref={ref} />
            <div
              className={classNames(styles.videoPlaceholder, {
                [styles.videoPlaceholder__noItems]: hasNoVideoItems,
              })}
              ref={measureRef}
            />
            <div className={styles.mux} style={videoStyles}>
              <LoadableVideoPlayer
                muxPlaybackId={mux_playback_id}
                source={videoSource}
                ref={(video) => {
                  setVideoRef(video);
                }}
              />
              {videoDocked && (
                <button onClick={() => setUserUndocked(true)} className={styles.unDockButton}>
                  <CloseOutlined />
                </button>
              )}
            </div>
          </div>
          {standardLayout && isDesktop && (
            <div
              className={classNames(styles.products, {
                [styles.products__noProducts]: hasNoVideoItems,
              })}
            >
              {VideoItemsList}
            </div>
          )}
        </div>
        {standardLayout && <>{isDesktop ? VideoInfo : VerticalProductsList}</>}
        {verticalLayout && VerticalProductsList}
      </section>

      <BrandModal
        open={brandModalOpen}
        onCancel={() => {
          setBrandModalOpen(false);
        }}
        brand={selectedBrand}
      />
      <SpeciesModal
        open={speciesModalOpen}
        onCancel={() => {
          setSpeciesModalOpen(false);
        }}
        species={selectedSpecies}
      />
      <WaterbodyModal
        open={waterbodyModalOpen}
        onCancel={() => {
          setWaterbodyModalOpen(false);
        }}
        waterbody={selectedWaterbody}
      />
      <TechniqueModal
        open={techniqueModalOpen}
        onCancel={() => {
          setTechniqueModalOpen(false);
        }}
        technique={selectedTechnique}
      />
      <GetVideoSchema omnia_video={omnia_video} omnia_video_items={omnia_video_items} plyr={plyr} />
    </>
  );
};
