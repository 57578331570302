import {
  LoadingOutlined,
  MailOutlined,
  RightOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';
import { FishingReport, FishingReportWithWaterbodyDetail } from '@omniafishing/core';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import iconPlay from '../../assets/icon-play.svg';
import { useCart } from '../../hooks/use_cart';
import { useListAttributionUuid } from '../../hooks/use_list_attribution_uuid';
import { useResponsive } from '../../hooks/use_responsive';
import { getImgixPath } from '../../lib/imgix';
import { customAttributesToAttributionQueryParams } from '../../lib/line_item_attribution';
import { setShopifyImgWidth } from '../../lib/set_shopify_img_width';
import { toDollars } from '../../lib/to_dollars';
import { OmniaUrls } from '../../lib/urls';
import { WebAnalytics, WebAnalyticsEventAreas } from '../../lib/web_analytics';
import { LineItemCustomAttribute, LINE_ATTRIBUTION } from '../../redux/cart';
import { InventoryModalActions } from '../../redux/inventory_modal';
import { getPageTypeByPathname, PageTypes } from '../../routes';
import { ProductCarousel } from '../carousel/product_carousel';
import { ChipDetails } from '../fishing_report_card/chip_details';
import { ActiveMedia, MediaModal } from '../fishing_report_card/media_modal';
import { ReportListDetails } from '../fishing_report_card/report_list_details';
import { ShareReportButton } from '../fishing_report_card/share_report_button';
import ImageLoader from '../image_loader/image_loader';
import useImageLoader from '../image_loader/use_image_loader';
import { OmniaButton } from '../omnia_button/omnia_button';
import SvgExpandIcon from '../svg/expand_icon';
import SvgFire from '../svg/fire';
import { WaterbodyImg } from '../waterbody_img/waterbody_img';
import styles from './fishing_report_modal_card.less';

interface FishingReportModalCardProps {
  adaptiveProductCarouselHeight?: boolean;
  className?: string;
  defaultMediaType?: 'image' | 'video';
  fishingReport: FishingReport | FishingReportWithWaterbodyDetail;
  lineItemAttribution?: LineItemCustomAttribute[];
  onClose?: () => void;
  onLakeClick?: () => void;
  onMediaClick?: () => void;
  onProductClick?: () => void;
  onShareClick?: () => void;
  onUserClick?: () => void;
  position: number;
  showLakeCTA?: boolean;
}

export const FishingReportModalCard = (props: FishingReportModalCardProps) => {
  const {
    adaptiveProductCarouselHeight,
    className,
    defaultMediaType = 'image',
    fishingReport,
    lineItemAttribution = [],
    onClose,
    onLakeClick,
    onMediaClick,
    onProductClick,
    onShareClick,
    onUserClick,
    position,
    showLakeCTA = true,
  } = props;

  const { comments, featured_product, image, video, waterbody, products } = fishingReport;

  const { isDesktop } = useResponsive();
  const location = useLocation();
  const pathIsLakePage = getPageTypeByPathname(location.pathname) === PageTypes.LAKE_PAGE;

  const fishingReportProducts = [featured_product, ...products].filter(Boolean);

  const hasProducts = fishingReportProducts.length > 0;

  const hasImage = !!image;
  const hasVideo = !!video;
  const hasMedia = hasImage || hasVideo;
  const hasImageAndVideo = hasImage && hasVideo;

  const [mediaType, setMediaType] = useState(
    hasImageAndVideo ? defaultMediaType : hasImage ? 'image' : 'video'
  );
  const [activeExpandedMedia, setActiveExpandedMedia] = useState<ActiveMedia>(null);
  const [imageBelowMinHeight, setImageBelowMinHeight] = useState(false);
  const { isImageLoading, isImageError, handleImageLoad, handleImageError, handleImageReload } =
    useImageLoader();
  const commentsRef = useRef<HTMLQuoteElement>();

  const handleMediaClick = (
    e: React.MouseEvent<HTMLImageElement | HTMLButtonElement, MouseEvent>,
    type: 'image' | 'video'
  ) => {
    e.stopPropagation();

    if (type === 'image') {
      setActiveExpandedMedia({
        src: getImgixPath(image, { w: 1000 }),
        type: 'img',
      });
    } else {
      setActiveExpandedMedia({
        src: video.src,
        type: 'video',
      });
    }
    onMediaClick?.();
    WebAnalytics.fishingReportEmbeddedClick(
      '[reports].(media_link)',
      fishingReport,
      'embedded',
      position
    );
  };

  const onImageLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
    const { naturalHeight: height } = event.target as HTMLImageElement;
    const desktopMinHeight = 293;
    const mobileMinHeight = window.innerHeight * 0.33;
    setImageBelowMinHeight(isDesktop ? height < desktopMinHeight : height < mobileMinHeight);
    handleImageLoad();
  };

  const imagePath =
    image &&
    getImgixPath(image, {
      w: 387,
      crop: 'faces',
    });

  const imageVideoPath =
    video &&
    getImgixPath(video.image, {
      w: 387,
      crop: 'faces',
    });

  const Video = video && (
    <>
      <img className={styles.imgBackground} src={imageVideoPath} />
      <ImageLoader
        className={styles.videoImage}
        isImageError={isImageError}
        isImageLoading={isImageLoading}
        loadingClassName={styles.loading}
        onClick={(e) => handleMediaClick(e, 'video')}
        onError={handleImageError}
        onLoad={(e) => onImageLoad(e)}
        src={imageVideoPath}
      />
      {hasImageAndVideo && (
        <span
          className={styles.seeMediaChip}
          onClick={() => {
            setMediaType('image');
            handleImageReload();
          }}
        >
          See Image
        </span>
      )}
      {!isImageError && (
        <>
          <img
            src={iconPlay}
            className={styles.bigPlayIcon}
            onClick={(e) => handleMediaClick(e, 'video')}
          />
          <button className={styles.expandIcon} onClick={(e) => handleMediaClick(e, 'video')}>
            <SvgExpandIcon />
          </button>
        </>
      )}
    </>
  );

  const Image = image && (
    <>
      <img className={styles.imgBackground} src={imagePath} />
      <ImageLoader
        className={classNames({
          [styles.image__belowMinHeight]: imageBelowMinHeight,
          [styles.image]: !imageBelowMinHeight,
        })}
        isImageError={isImageError}
        isImageLoading={isImageLoading}
        loadingClassName={styles.loading}
        onClick={(e) => handleMediaClick(e, 'image')}
        onError={handleImageError}
        onLoad={(e) => onImageLoad(e)}
        src={imagePath}
      />
      {hasImageAndVideo && (
        <span
          className={styles.seeMediaChip}
          onClick={() => {
            setMediaType('video');
            handleImageReload();
          }}
        >
          See Video
        </span>
      )}
      {!isImageError && (
        <button className={styles.expandIcon} onClick={(e) => handleMediaClick(e, 'image')}>
          <SvgExpandIcon />
        </button>
      )}
    </>
  );

  const [mapImageLoaded, setMapImageLoaded] = useState(false);

  const [showFullComments, setShowFullComments] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  useLayoutEffect(() => {
    setIsOverflowing(commentsRef.current?.scrollHeight > commentsRef.current?.clientHeight);
  }, [showFullComments, comments]);

  const list_uuid = useListAttributionUuid();

  const hasFeaturedProduct = fishingReport.featured_product != null;
  const lineItemCustomAttributes: LineItemCustomAttribute[] = _.uniqBy(
    [
      ...lineItemAttribution,
      {
        key: LINE_ATTRIBUTION.FISHING_REPORT_ID,
        value: fishingReport.id.toString(),
      },
    ],
    'key'
  );

  const handleProductClick = useCallback(
    (isHotbait: boolean) => {
      onProductClick?.();
      WebAnalytics.fishingReportEmbeddedClick(
        isHotbait ? '[reports].(hotbait)' : '[reports].(products)',
        fishingReport,
        'embedded',
        position
      );
    },
    [fishingReport, list_uuid, position]
  );

  const { addToCart } = useCart();
  const dispatch = useDispatch();

  return (
    <>
      <section
        className={classNames(styles.fishingReportCard, className, {
          [styles.fishingReportCard__noProducts]: !hasProducts,
          [styles.fishingReportCard__noFooter]: pathIsLakePage,
        })}
      >
        <div className={styles.hero}>
          {hasMedia ? (
            <div
              className={classNames(styles.mediaContainer, {
                [styles.mediaContainer__fullComments]: showFullComments,
                [styles.mediaContainer__error]: isImageError,
              })}
            >
              {mediaType === 'image' ? Image : Video}
              <ChipDetails
                fishingReport={fishingReport}
                onUserClick={onUserClick}
                onLakeClick={onLakeClick}
                position={position}
              />
              <ShareReportButton
                fishingReport={fishingReport}
                onShareClick={onShareClick}
                className={styles.shareReportButton}
              />
              {!isImageError && (
                <MediaModal
                  activeMedia={activeExpandedMedia}
                  onCancel={() => setActiveExpandedMedia(null)}
                />
              )}
            </div>
          ) : (
            <div className={styles.mapContainer}>
              {!mapImageLoaded && (
                <div className={styles.loading}>
                  <LoadingOutlined style={{ fontSize: 24 }} spin />
                </div>
              )}
              <Link
                to={OmniaUrls.waterbody(waterbody)}
                onClick={() => {
                  onLakeClick?.();
                  onClose?.();
                }}
                target={isDesktop ? '_blank' : undefined}
              >
                <div className={styles.map}>
                  <WaterbodyImg
                    waterbody={waterbody}
                    size={{ width: 550, height: showFullComments ? (isDesktop ? 245 : 235) : 293 }}
                    showPin={false}
                    lazyLoad={false}
                    onLoad={() => setMapImageLoaded(true)}
                  />
                </div>
              </Link>
              <ChipDetails
                className={styles.chipDetailsContainer__withBorder}
                fishingReport={fishingReport}
                onUserClick={onUserClick}
                onLakeClick={onLakeClick}
                position={position}
              />
              <ShareReportButton fishingReport={fishingReport} onShareClick={onShareClick} />
            </div>
          )}
        </div>

        <section className={styles.reportDetails}>
          <ReportListDetails report={fishingReport} />
          {comments && (
            <div className={styles.commentsWrapper}>
              <q
                className={classNames(styles.comments, {
                  [styles.comments__showFull]: showFullComments,
                })}
                ref={commentsRef}
              >
                {comments}
              </q>{' '}
              {(isOverflowing || showFullComments) && (
                <OmniaButton
                  kind="text"
                  size="sm"
                  onClick={() => {
                    setShowFullComments(!showFullComments);
                  }}
                  style={{ padding: 0 }}
                >
                  Read {showFullComments ? 'Less' : 'More'}
                </OmniaButton>
              )}
            </div>
          )}
          {hasProducts && (
            <ul className={styles.carouselWrapper}>
              <ProductCarousel
                products={fishingReportProducts}
                key={fishingReportProducts.map((product) => product.sku).join()}
                list_id_title="Products Associated With This Report"
                list_id_type="report"
                arrows
                list_uuid={list_uuid}
                fadedEdges
                adaptive={adaptiveProductCarouselHeight}
              >
                {fishingReportProducts.map((product, i) => {
                  const isHotbait = hasFeaturedProduct && i === 0;
                  const {
                    img_url,
                    title,
                    on_sale,
                    price,
                    compare_at_price,
                    shopify_product_handle,
                    shopify_options,
                  } = product;

                  const outOfStock = !product.in_stock;
                  const productUrl = OmniaUrls.product(shopify_product_handle, shopify_options);
                  const productUrlSearch = `?${customAttributesToAttributionQueryParams(
                    lineItemCustomAttributes
                  )}`;

                  return (
                    <li key={`${product.sku}_${isHotbait}`} className={styles.productCard}>
                      <div className={styles.product}>
                        <Link
                          to={{
                            pathname: productUrl,
                            search: productUrlSearch,
                          }}
                          className={styles.imgLink}
                          onClick={() => {
                            if (list_uuid) {
                              WebAnalytics.productClick({
                                productOrProductFamily: product,
                                show_modal: false,
                                position: i,
                                list_uuid,
                              });
                              handleProductClick?.(isHotbait);
                              onClose?.();
                            }
                          }}
                          target={isDesktop ? '_blank' : undefined}
                        >
                          <div className={styles.productImgContainer}>
                            <img
                              src={setShopifyImgWidth(img_url, 300)}
                              alt={title}
                              className={styles.productImg}
                            />
                            {isHotbait && (
                              <span className={styles.hotbaitContainer}>
                                <SvgFire className={styles.fire} />
                                Hotbait
                              </span>
                            )}
                          </div>
                        </Link>

                        <div className={styles.cardContents}>
                          <div className={styles.description}>
                            <Link
                              to={{
                                pathname: productUrl,
                                search: productUrlSearch,
                              }}
                              onClick={() => {
                                WebAnalytics.productClick({
                                  productOrProductFamily: product,
                                  show_modal: false,
                                  position: i,
                                  list_uuid,
                                });
                                handleProductClick?.(isHotbait);
                                onClose?.();
                              }}
                              target={isDesktop ? '_blank' : undefined}
                            >
                              <p className={styles.title}>{title}</p>
                            </Link>
                            {outOfStock ? (
                              <p className={styles.price}>Out of Stock</p>
                            ) : on_sale ? (
                              <div className={styles.sale}>
                                <p className={styles.price}>{toDollars(price)}</p>
                                <s className={styles.priceCompare}>{toDollars(compare_at_price)}</s>
                              </div>
                            ) : (
                              <p className={styles.price}>{toDollars(price)}</p>
                            )}
                          </div>
                          <div className={styles.productCardFooter}>
                            {outOfStock ? (
                              <Tooltip title="Sign up to be emailed as soon as it's back in stock">
                                <div>
                                  <OmniaButton
                                    size="md"
                                    kind="tertiary"
                                    fontSize={14}
                                    onClick={() => {
                                      dispatch(
                                        InventoryModalActions.INVENTORY_MODAL_OPEN(
                                          product,
                                          WebAnalyticsEventAreas.REPORTS_VIEW
                                        )
                                      );
                                      onClose?.();
                                    }}
                                  >
                                    <MailOutlined />
                                    Email Me
                                  </OmniaButton>
                                </div>
                              </Tooltip>
                            ) : (
                              <OmniaButton
                                kind="primary"
                                size="md"
                                fontSize={14}
                                onPress={() => {
                                  addToCart({
                                    productsToAdd: [
                                      {
                                        product,
                                        quantity: 1,
                                      },
                                    ],
                                    addToCartArea: WebAnalyticsEventAreas.REPORTS_VIEW,
                                    customAttributes: lineItemCustomAttributes,
                                    position: i,
                                    list_uuid,
                                  });
                                  onClose?.();
                                }}
                              >
                                <ShoppingCartOutlined /> Add to Cart
                              </OmniaButton>
                            )}
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ProductCarousel>
            </ul>
          )}
        </section>
      </section>
      {showLakeCTA && (
        <Link to={OmniaUrls.waterbody(waterbody)} onClick={() => onClose?.()}>
          <div className={styles.cardFooter}>
            <OmniaButton
              kind="tertiary"
              size="md"
              onClick={() => {
                onLakeClick?.();
                onClose?.();
              }}
            >
              View {waterbody.primary_name}
              {isDesktop && <RightOutlined />}
            </OmniaButton>
          </div>
        </Link>
      )}
    </>
  );
};
