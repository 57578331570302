import { Product } from '@omniafishing/core';
import _ from 'lodash';
import React, { ReactNode, useEffect } from 'react';
import { useListViewed, WebAnalyticsListViewedType } from '../../hooks/use_list_viewed';
import { trackJs } from '../../lib/trackjs';
import { WebAnalytics } from '../../lib/web_analytics';
import OmniaCarousel from './omnia_carousel';

interface ProductCarouselProps {
  adaptive?: boolean;
  additionalKeyProps?: string;
  arrows: boolean;
  children: ReactNode;
  fadedEdges?: boolean;
  list_id_title?: string;
  list_id_type?: WebAnalyticsListViewedType;
  list_uuid?: string;
  products: Product[];
  resizeOnContentsReady?: boolean;
  renderOnlyVisible?: boolean;
}

export const ProductCarousel = (props: ProductCarouselProps) => {
  const {
    adaptive,
    additionalKeyProps,
    arrows,
    children,
    fadedEdges = true,
    list_id_title,
    list_id_type,
    list_uuid,
    products,
    renderOnlyVisible = false,
  } = props;
  const productSkus = products.map((product) => product.sku);
  const productSkusString = productSkus.join();
  const id_type = list_id_type ?? 'carousel';

  const { coupon, createListId } = useListViewed();

  const childrenKeys = _.flatten(
    React.Children.map(children, (child) => {
      if (child?.hasOwnProperty('key')) {
        return (child as JSX.Element).key;
      }
    })
  );
  const hasDuplicateKeys = _.uniq(childrenKeys).length !== childrenKeys.length;
  if (hasDuplicateKeys) {
    trackJs('Duplicate ProductCarousel keys found');
  }

  useEffect(() => {
    WebAnalytics.productListViewed({
      products,
      coupon,
      list_id: createListId(id_type, list_id_title),
      list_uuid,
    });
  }, [productSkusString]);

  const keyString = additionalKeyProps
    ? `${productSkusString}${additionalKeyProps}`
    : productSkusString;

  return (
    <OmniaCarousel
      adaptive={adaptive}
      arrows={arrows}
      fadedEdges={fadedEdges}
      key={keyString}
      newArrows={true}
      renderOnlyVisible={renderOnlyVisible}
    >
      {children}
    </OmniaCarousel>
  );
};
