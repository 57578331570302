import algoliasearch from 'algoliasearch';
import { getEnv } from './env';

const env = getEnv();
export const searchClient = algoliasearch(env.ALGOLIA_APPLICATION_ID, env.ALGOLIA_SEARCH_KEY);
export const ambassadorsIndex = searchClient.initIndex(env.ALGOLIA_INDEX_AMBASSADORS);
export const brandsIndex = searchClient.initIndex(env.ALGOLIA_INDEX_BRANDS);
export const mediaDateSortedIndex = searchClient.initIndex(getEnv().ALGOLIA_INDEX_MEDIA_DATE_DESC);
export const mediaIndex = searchClient.initIndex(getEnv().ALGOLIA_INDEX_MEDIA);
export const productFamiliesIndex = searchClient.initIndex(env.ALGOLIA_INDEX_PRODUCT_FAMILIES);
export const productsIndex = searchClient.initIndex(env.ALGOLIA_INDEX_PRODUCTS);
export const waterbodiesIndex = searchClient.initIndex(env.ALGOLIA_INDEX_WATERBODIES);

export enum AlgoliaSearchEvents {
  clickedObjectIDsAfterSearch = 'clickedObjectIDsAfterSearch',
}

export enum AlgoliaNonSearchEvents {
  viewedObjectIds = 'viewedObjectIds',
  clickedObjectIds = 'clickedObjectIds',
  convertedObjectIds = 'convertedObjectIDs',
}
