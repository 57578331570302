import loadable from '@loadable/component';
import 'antd/dist/antd.less';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import searchInsights from 'search-insights';
import { AppStaticRouterContext } from '../../types/static-context';
import {
  getCategoryBySubcategoryUrl,
  getSubcategoryByUrlPath,
  getSubcatTypeBySlug,
} from '../categories_subcategories';
import { AffiliateChecker } from '../components/affiliate_checker/affiliate_checker';
import { AffiliateLinkBuilder } from '../components/affiliate_link_builder/affiliate_link_builder';
import { BodyOverflowHandler } from '../components/body_overflow_handler/body_overflow_handler';
import { CartContainer } from '../components/cart/cart_container';
import { CartDiscountHandler } from '../components/cart_discount_handler/cart_discount_handler';
import { CartProductAdder } from '../components/cart_product_adder/cart_product_adder';
import ErrorBoundary from '../components/error_boundary/error_boundary';
import ErrorPage from '../components/error_page/error_page';
import { ExperimentHandler } from '../components/experiment_handler/experiment_handler';
import { FishingReportDisplayModalShower } from '../components/fishing_report_display_modal/fishing_report_display_modal_shower';
import { FishingReportModal } from '../components/fishing_report_modal/fishing_report_modal';
import FlashNotification from '../components/flash_message/flash_notification';
import Footer from '../components/footer/footer';
import { GeographicLocationHandler } from '../components/geographic_location_handler/geographic_location_handler';
import { GuestRedirect } from '../components/guest_redirect/guest_redirect';
import { HeaderDesktop } from '../components/header/header_desktop';
import { HeaderMobile } from '../components/header/header_mobile';
import { PromoBar } from '../components/header/promo_bar';
import InventoryModalContainer from '../components/inventory_modal/inventory_modal_container';
import { LocationChanger } from '../components/location_changer/location_changer';
import { SignupLoginModal } from '../components/login_signup_modal/signup_login_modal';
import { MicrosoftClarityIntegration } from '../components/microsoft_clarity_integration/microsoft_clarity_integration';
import { NewsletterPopup } from '../components/newsletter_popup/newsletter_popup';
import { ProductsNav } from '../components/products_nav/products_nav';
import ProductFamilyQuickModalContainer from '../components/product_family_quick_modal/product_family_quick_modal_container';
import { ProUpsellModal } from '../components/pro_upsell_modal/pro_upsell_modal';
import { ScrollToTopButton } from '../components/scroll_to_top_button/scroll_to_top_button';
import { SEO } from '../components/seo/seo';
import { ShortUrlChecker } from '../components/short_url_checker/short_url_checker';
import UserAuthHandlerContainer from '../components/user_auth_handler/user_auth_handler_container';
import { UserCartAssociator } from '../components/user_cart_associator/user_cart_associator';
import { UserCartAttributesHandler } from '../components/user_cart_attributes_handler/user_cart_attributes_handler';
import { UserCartHandler } from '../components/user_cart_handler/user_cart_handler';
import { UserDataHandler } from '../components/user_data_handler/user_data_handler';
import { VersionChecker } from '../components/version_checker/version_checker';
import WindowSizeContainer from '../components/window_listener/window_listener_container';
import { getEnv } from '../env';
import { useDimensions } from '../hooks/use_dimensions';
import { splitGetStartedUrl } from '../lib/urls';
import { getPath } from '../redux/router';
import { BestOfPaths, DashboardPaths, RoutePaths, WaterbodyPaths } from '../routes';
import '../styles/ant_style_overrides.less';
import base from '../styles/base.less';
import '../styles/global.less';
import '../styles/tailwind.css';
import '../styles/typography.less';
import { AppsFlyerSmartBannerResolver } from './root/apps_flyer_smart_banner_resolver';
import { LineItemCustomAttributeFixer } from './root/line_item_custom_attribute_fixer';
import { RootProps } from './root_container';

const env = getEnv();

searchInsights('init', {
  appId: env.ALGOLIA_APPLICATION_ID,
  apiKey: env.ALGOLIA_SEARCH_KEY,
  useCookie: true,
});

// ---------------------------- !!! IMPORTANT !!! ----------------------------
// webpackChunkName must follow file name, with folders as dashes
// for example: static/about_page -> static-about_page
// ---------------------------- !!! IMPORTANT !!! ----------------------------

const HomePage = loadable(() => import(/* webpackChunkName: "home_page" */ './home_page'));

const AboutPage = loadable(
  () => import(/* webpackChunkName: "static-about_page" */ './static/about_page')
);

const AmbassadorApplicationFormPage = loadable(
  () =>
    import(
      /* webpackChunkName: "ambassador_application_form_page" */ './ambassador_application_form_page'
    )
);
const AmbassadorsPage = loadable(
  () => import(/* webpackChunkName: "ambassadors_page" */ './ambassadors_page')
);
const AmbassadorsLeaderboardPage = loadable(
  () =>
    import(/* webpackChunkName: "ambassadors_leaderboard_page" */ './ambassadors_leaderboard_page')
);
const AmbassadorApplicationPage = loadable(
  () =>
    import(
      /* webpackChunkName: "ambassador_application_page-ambassador_application_page" */ './ambassador_application_page/ambassador_application_page'
    )
);

const AppPage = loadable(
  () => import(/* webpackChunkName: "app_page-app_page" */ './app_page/app_page')
);

const BestOfYearPage = loadable(
  () =>
    import(
      /* webpackChunkName: "best_of_year-best_of_year_page" */ './best_of_year/best_of_year_page'
    )
);
const BestOfYearLakePageContainer = loadable(
  () =>
    import(
      /* webpackChunkName: "best_of_year-best_of_year_lake_page_container" */ './best_of_year/best_of_year_lake_page_container'
    )
);

const BestOfYearStatePageContainer = loadable(
  () =>
    import(
      /* webpackChunkName: "best_of_year-best_of_year_state_page_container" */ './best_of_year/best_of_year_state_page_container'
    )
);

const BestSellersPage = loadable(
  () => import(/* webpackChunkName: "best_sellers_page" */ './best_sellers_page')
);

const BigSelection = loadable(
  () =>
    import(
      /* webpackChunkName: "big_selection-big_selection_page"*/ './big_selection/big_selection_page'
    )
);

const BlackFridayCyberMondayPage = loadable(
  () =>
    import(
      /* webpackChunkName: "static-black_friday_cyber_monday_page" */ './static/black_friday_cyber_monday_page'
    )
);

const CartBinsPage = loadable(
  () => import(/* webpackChunkName: "cart_bins_page" */ './cart_bins_page')
);
const ArticlePage = loadable(() => import(/* webpackChunkName: "article_page" */ './article_page'));
const BrandsPage = loadable(() => import(/* webpackChunkName: "brands_page" */ './brands_page'));
const BrandPageContainer = loadable(
  () => import(/* webpackChunkName: "brand_page_container" */ './brand_page_container')
);
const CartClearPage = loadable(
  () => import(/* webpackChunkName: "cart_clear_page" */ './cart_clear_page')
);
const CategoryPageContainer = loadable(
  () => import(/* webpackChunkName: "category_page_container" */ './category_page_container')
);
const ChangeEmailPage = loadable(
  () => import(/* webpackChunkName: "change_email_page" */ './change_email_page')
);
const ConfirmPage = loadable(() => import(/* webpackChunkName: "confirm_page" */ './confirm_page'));
const UserEventNotificationsPage = loadable(
  () =>
    import(
      /* webpackChunkName: "technique_page-user_event_notifications_page" */ './dashboard/user_event_notifications_page'
    )
);
const DashboardGuestPage = loadable(
  () => import(/* webpackChunkName: "dashboard_guest_page" */ './dashboard_guest_page')
);
const DashboardPage = loadable(
  () => import(/* webpackChunkName: "dashboard_page" */ './dashboard_page')
);

const DashboardGroupsPage = loadable(
  () => import(/* webpackChunkName: "dashboard_groups_page" */ './dashboard_groups_page')
);

const DealsPage = loadable(() => import(/* webpackChunkName: "deals_page" */ './deals_page'));
const EmailSentPage = loadable(
  () => import(/* webpackChunkName: "email_sent_page" */ './email_sent_page')
);
const FishingReportsPageContainer = loadable(
  () =>
    import(
      /* webpackChunkName: "fishing_reports_page_container" */ './fishing_reports_page_container'
    )
);
const FishingReportsRulesPage = loadable(
  () => import(/* webpackChunkName: "fishing_reports_rules_page" */ './fishing_reports_rules_page')
);
const FishingReportWeeklyPage = loadable(
  () =>
    import(
      /* webpackChunkName: "fishing_reports_weekly_page-fishing_reports_weekly_page" */ './fishing_reports_weekly_page/fishing_reports_weekly_page'
    )
);
const FishingReportPageContainer = loadable(
  () =>
    import(
      /* webpackChunkName: "fishing_report_page_container" */ './fishing_report_page_container'
    )
);
const GetStartedUninitiatedPage = loadable(
  () =>
    import(
      /* webpackChunkName: "get_started-get_started_uninitiated_page" */ './get_started/get_started_uninitiated_page'
    )
);

const GetStartedPage = loadable(
  () =>
    import(/* webpackChunkName: "get_started-get_started_page" */ './get_started/get_started_page')
);

const GoPage = loadable(
  () => import(/* webpackChunkName: "go_page-go_page" */ './go_page/go_page')
);

const JackLinksPage = loadable(
  () =>
    import(
      /* webpackChunkName: "jack_links_page-jack_links_page"*/ './jack_links_page/jack_links_page'
    )
);

const JobsPage = loadable(() => import(/* webpackChunkName: "jobs_page" */ './jobs_page'));

const KitPageContainer = loadable(
  () =>
    import(/* webpackChunkName: "kit_page-kit_page_container" */ './kit_page/kit_page_container')
);

const LandingPagePage = loadable(
  () => import(/* webpackChunkName: "landing_page_page" */ './landing_page_page')
);
const ListPageContainer = loadable(
  () => import(/* webpackChunkName: "list_page_container" */ './list_page_container')
);
const MediaPage = loadable(
  () => import(/* webpackChunkName: "media_page-media_page" */ './media_page/media_page')
);
const OmniaVideoPage = loadable(
  () => import(/* webpackChunkName: "omnia_video_page" */ './omnia_video_page')
);
const OmniaVideoAdminPageContainer = loadable(
  () =>
    import(
      /* webpackChunkName: "omnia_video_admin_page-omnia_video_admin_page_container" */ './omnia_video_admin_page/omnia_video_admin_page_container'
    )
);
const OneLinkResolver = loadable(
  () => import(/* webpackChunkName: "one_link_resolver" */ './one_link_resolver')
);
const ProMediaPage = loadable(
  () =>
    import(
      /* webpackChunkName: "pro_media_page-pro_media_page" */ './pro_media_page/pro_media_page'
    )
);
const LoginPage = loadable(() => import(/* webpackChunkName: "login_page" */ './login_page'));
const MapPageContainer = loadable(
  () =>
    import(/* webpackChunkName: "map_page-map_page_container" */ './map_page/map_page_container')
);
const NewsDetailPage = loadable(
  () => import(/* webpackChunkName: "news_detail_page" */ './news_detail_page')
);
const NewsletterPage = loadable(
  () =>
    import(
      /* webpackChunkName: "newsletter_page-newsletter_page" */ './newsletter_page/newsletter_page'
    )
);
const NewsletterPageSuccess = loadable(
  () =>
    import(
      /* webpackChunkName: "newsletter_page-newsletter_page_success" */ './newsletter_page/newsletter_page_success'
    )
);
const NewsPage = loadable(() => import(/* webpackChunkName: "news_page" */ './news_page'));
const NewProductsPage = loadable(
  () => import(/* webpackChunkName: "new_products_page" */ './new_products_page')
);
const OmniaBlueLandingPage = loadable(
  () =>
    import(
      /* webpackChunkName: "omnia_blue_landing_page-omnia_blue_landing_page" */ './omnia_blue_landing_page/omnia_blue_landing_page'
    )
);

const OrdersPage = loadable(() => import(/* webpackChunkName: "orders_page" */ './orders_page'));
const OrgsPage = loadable(() => import(/* webpackChunkName: "orgs_page" */ './orgs_page'));
const OrgPage = loadable(() => import(/* webpackChunkName: "org_page" */ './org_page'));
const PasswordResetConfirmPage = loadable(
  () =>
    import(/* webpackChunkName: "password_reset_confirm_page" */ './password_reset_confirm_page')
);
const PasswordResetPage = loadable(
  () => import(/* webpackChunkName: "password_reset_page" */ './password_reset_page')
);
const PersonalShoppingPage = loadable(
  () =>
    import(
      /* webpackChunkName: "personal_shopping_page-personal_shopping_page" */ './personal_shopping_page/personal_shopping_page'
    )
);

const ProPageContainer = loadable(
  () =>
    import(/* webpackChunkName: "pro_page-pro_page_container" */ './pro_page/pro_page_container')
);

const ProOfferPage = loadable(
  () => import(/* webpackChunkName: "pro_offer-pro_offer" */ './pro_offer/pro_offer')
);

const OmniaProPurchaseCompletePage = loadable(
  () =>
    import(
      /* webpackChunkName: "omnia_pro_purchase_complete_page-omnia_pro_purchase_complete_page" */ './omnia_pro_purchase_complete_page/omnia_pro_purchase_complete_page'
    )
);
const ProductDetailPageContainer = loadable(
  () =>
    import(
      /* webpackChunkName: "product_detail_page_container" */ './product_detail_page_container'
    )
);
const ProfileEditPage = loadable(
  () => import(/* webpackChunkName: "profile_edit_page" */ './profile_edit_page')
);

const SalePage = loadable(() => import(/* webpackChunkName: "sale_page" */ './sale_page'));
const SaleProPage = loadable(
  () =>
    import(/* webpackChunkName: "sale_pro_page-sale_pro_page" */ './sale_pro_page/sale_pro_page')
);

const SetAlerts = loadable(
  () => import(/* webpackChunkName: "set_alerts-set_alerts_page"*/ './set_alerts/set_alerts_page')
);

const ShopPage = loadable(
  () => import(/* webpackChunkName: "shop_page-shop_page" */ './shop_page/shop_page')
);

const SignupGiveawayPage = loadable(
  () => import(/* webpackChunkName: "signup_giveaway_page" */ './signup_giveaway_page')
);
const SignupGiveawayEnteredPage = loadable(
  () =>
    import(/* webpackChunkName: "signup_giveaway_entered_page" */ './signup_giveaway_entered_page')
);
const SignupPage = loadable(() => import(/* webpackChunkName: "signup_page" */ './signup_page'));
const SignupFollowupPage = loadable(
  () => import(/* webpackChunkName: "signup_followup_page" */ './signup_followup_page')
);
const SpeciesListPage = loadable(
  () => import(/* webpackChunkName: "species_list_page" */ './species_list_page')
);
const SpeciesPage = loadable(() => import(/* webpackChunkName: "species_page" */ './species_page'));
const StatesPage = loadable(() => import(/* webpackChunkName: "states_page" */ './states_page'));
const StatePage = loadable(() => import(/* webpackChunkName: "state_page" */ './state_page'));
const FaqsPage = loadable(
  () => import(/* webpackChunkName: "static-faqs_page" */ './static/faqs_page')
);
const PrivacyPage = loadable(
  () => import(/* webpackChunkName: "static-privacy_page" */ './static/privacy_page')
);
const ShippingReturnsPage = loadable(
  () =>
    import(/* webpackChunkName: "static-shipping_returns_page" */ './static/shipping_returns_page')
);
const TermsPage = loadable(
  () => import(/* webpackChunkName: "static-terms_page" */ './static/terms_page')
);
const WhoWeArePage = loadable(
  () =>
    import(
      /* webpackChunkName: "static-who_we_are-who_we_are_page" */ './static/who_we_are/who_we_are_page'
    )
);
const StylesPage = loadable(() => import(/* webpackChunkName: "styles_page" */ './styles_page'));
const TechniquePage = loadable(
  () =>
    import(
      /* webpackChunkName: "technique_page-technique_page" */ './technique_page/technique_page'
    )
);
const StyleKeywordPage = loadable(
  () =>
    import(
      /* webpackChunkName: "style_keyword_page-style_keyword_page" */ './style_keyword_page/style_keyword_page'
    )
);
const SubcategoryPage = loadable(
  () => import(/* webpackChunkName: "subcategory_page" */ './subcategory_page')
);
const SubcatTypePage = loadable(
  () => import(/* webpackChunkName: "subcat_type_page" */ './subcat_type_page')
);
const TournamentsPage = loadable(
  () => import(/* webpackChunkName: "tournaments_page" */ './tournaments_page')
);
const TournamentDetailPage = loadable(
  () => import(/* webpackChunkName: "tournament_detail_page" */ './tournament_detail_page')
);
const TournamentSubmitPage = loadable(
  () => import(/* webpackChunkName: "tournament_submit_page" */ './tournament_submit_page')
);
const UserFishingReportsPage = loadable(
  () => import(/* webpackChunkName: "user_fishing_reports_page" */ './user_fishing_reports_page')
);
const AmbassadorProfilePageContainer = loadable(
  () =>
    import(
      /* webpackChunkName: "ambassador_profile_page-ambassador_profile_page_container" */ './ambassador_profile_page/ambassador_profile_page_container'
    )
);
const VideoPage = loadable(() => import(/* webpackChunkName: "video_page" */ './video_page'));
const WaterbodyDetailRouter = loadable(
  () => import(/* webpackChunkName: "waterbody_detail_router" */ './waterbody_detail_router')
);

const WeShipFast = loadable(
  () =>
    import(
      /* webpackChunkName: "we_ship_fast-we_ship_fast_page"*/ './we_ship_fast/we_ship_fast_page'
    )
);

const WildRiverPage = loadable(
  () =>
    import(
      /* webpackChunkName: "wild_river_page-wild_river_page"*/ './wild_river_page/wild_river_page'
    )
);

const WishlistPage = loadable(
  () => import(/* webpackChunkName: "wishlist_page" */ './wishlist_page')
);

const WishlistContestPage = loadable(
  () =>
    import(
      /* webpackChunkName: "wishlist_contest_page-wishlist_contest_page" */ './wishlist_contest_page/wishlist_contest_page'
    )
);

export const Root = (rootProps: RootProps) => {
  const {
    hasAccessToken,
    checkoutId,
    categories,
    subcategories,
    subcatTypes,
    styles,
    species,
    staticContext,
    isWebView,
    brands,
  } = rootProps;

  const pathname = useSelector(getPath);
  const showFooter =
    (!isWebView &&
      !(
        pathname === RoutePaths.MAP ||
        new RegExp(`^${RoutePaths.WATERS}/[^/]+${WaterbodyPaths.CURRENT_CONDITIONS}$`).test(
          pathname
        ) ||
        new RegExp(`^${RoutePaths.WATERS}/[^/]+${WaterbodyPaths.FISHING_REPORTS}$`).test(pathname)
      )) ||
    new RegExp(`^${RoutePaths.WATERS}/[^/]+${WaterbodyPaths.FISHING_PATTERNS}$`).test(pathname);

  const render404Page = () => {
    // we have to check if staticContext exists because it will
    // be undefined if rendered through a BrowserRouter
    if (staticContext) {
      staticContext.statusCode = 404;
    }

    return <ErrorPage context="not_found" />;
  };

  /*
     the ref below exists only because the header is rendered twice
     which will open the search modal twice if query params are present.
     if device guessing improves or architecture of search_universal changes
     this should be removed and related effects in search universal updated.
  */
  const searchModalVisibleRef = useRef(false);
  const [measureRefCallback, headerDimensions] = useDimensions({
    onElementResize: true,
  });

  return (
    <div className={base.site}>
      <SEO
        titleTemplate="%s | Omnia Fishing"
        title="Lakes &amp; Fishing Gear"
        description="Omnia Fishing is your one-stop-shop for smart fishing gear online. Try our shop-by-lake map and get better resources to land more fish. Learn more!"
      />

      {!isWebView && (
        <>
          <PromoBar />
          <HeaderMobile searchModalVisibleRef={searchModalVisibleRef} />
          <HeaderDesktop
            searchModalVisibleRef={searchModalVisibleRef}
            measureRefCallback={measureRefCallback}
          />
          <ProductsNav />
        </>
      )}

      <main className={base.main}>
        {/* ErrorBoundary within main to keep navigation */}
        <ErrorBoundary>
          <Switch>
            <Route path={RoutePaths.HOME} exact component={HomePage} />
            <Route
              path={RoutePaths.MAP}
              exact
              render={() => <MapPageContainer headerHeight={headerDimensions.height} />}
            />
            <Route
              path={`${RoutePaths.WATERS}/:url_slug`}
              render={(props: RouteComponentProps<{ url_slug: string }>) => {
                return (
                  <WaterbodyDetailRouter
                    {...props}
                    key={props.match.params.url_slug}
                    headerHeight={headerDimensions.height}
                  />
                );
              }}
            />
            <Route
              path={`${RoutePaths.CATEGORIES}/:category/:subcategory/:subcat_type`}
              exact
              render={(
                props: RouteComponentProps<{ subcategory: string; subcat_type: string }>
              ) => {
                const subcategoryUrlPath = props.match.params.subcategory;
                const subcatTypeSlug = props.match.params.subcat_type;
                const subcatType = getSubcatTypeBySlug(subcatTypes, subcatTypeSlug);
                const subcategory = getSubcategoryByUrlPath(subcategories, subcategoryUrlPath);
                const category = getCategoryBySubcategoryUrl(categories, subcategoryUrlPath);

                if (!category || !subcategory || !subcatType) {
                  return render404Page();
                }

                return (
                  <SubcatTypePage
                    category={category}
                    subcategory={subcategory}
                    subcatType={subcatType}
                    key={subcatTypeSlug}
                    {...props}
                  />
                );
              }}
            />
            <Route
              path={`${RoutePaths.CATEGORIES}/:category/:subcategory`}
              exact
              render={(props: RouteComponentProps<{ subcategory: string }>) => {
                const subcategoryUrlPath = props.match.params.subcategory;
                const subcategory = getSubcategoryByUrlPath(subcategories, subcategoryUrlPath);
                const category = getCategoryBySubcategoryUrl(categories, subcategoryUrlPath);

                if (!subcategory || !category) {
                  return render404Page();
                }

                return (
                  <SubcategoryPage
                    subcategoryUrlPath={subcategoryUrlPath}
                    categories={categories}
                    subcategories={subcategories}
                    key={subcategoryUrlPath}
                    {...props}
                  />
                );
              }}
            />
            <Route
              path={`${RoutePaths.CATEGORIES}/:category`}
              exact
              render={(props: RouteComponentProps<{ category: string }>) => {
                const category = categories.find((c) => c.url_path === props.match.params.category);
                const query = props.location.search;

                if (!category) {
                  return render404Page();
                }

                return (
                  <CategoryPageContainer
                    category={category}
                    query={query}
                    {...props}
                    key={category.url_path}
                  />
                );
              }}
            />
            <Route
              path={`${RoutePaths.SPECIES}/:species_url`}
              exact
              render={(props: RouteComponentProps<{ species_url: string }>) => {
                const speciesUrlPath = props.match.params.species_url;
                const selectedSpecies = species.find(
                  (specie) => specie.url_path === speciesUrlPath
                );
                if (!selectedSpecies) {
                  return render404Page();
                }
                return (
                  <SpeciesPage
                    {...props}
                    selectedSpecies={selectedSpecies}
                    key={selectedSpecies.name}
                  />
                );
              }}
            />
            <Route path={RoutePaths.SPECIES} component={SpeciesListPage} />
            <Route
              path={`${RoutePaths.TECHNIQUES}/:style_url/:style_keyword_slug`}
              exact
              render={(
                props: RouteComponentProps<{ style_url: string; style_keyword_slug: string }>
              ) => {
                const { style_url, style_keyword_slug } = props.match.params;
                const selectedStyle = styles.find((style) => style.url_path === style_url);
                if (!selectedStyle) {
                  return render404Page();
                }
                return (
                  <StyleKeywordPage
                    {...props}
                    style={selectedStyle}
                    slug={style_keyword_slug}
                    key={style_keyword_slug}
                  />
                );
              }}
            />
            <Route
              path={`${RoutePaths.TECHNIQUES}/:style_url`}
              exact
              render={(props: RouteComponentProps<{ style_url: string }>) => {
                const styleUrlPath = props.match.params.style_url;
                const selectedStyle = styles.find((style) => style.url_path === styleUrlPath);
                if (!selectedStyle) {
                  return render404Page();
                }
                return (
                  <TechniquePage {...props} technique={selectedStyle} key={selectedStyle.name} />
                );
              }}
            />
            <Route path={RoutePaths.TECHNIQUES} component={StylesPage} />
            <Route
              path={`${RoutePaths.PRODUCTS}/:product_handle/:variant_options?`}
              exact
              render={(
                props: RouteComponentProps<{ product_handle: string; variant_options?: string }>
              ) => (
                <ProductDetailPageContainer
                  key={props.match.params.product_handle}
                  productHandle={props.match.params.product_handle}
                  variantOptions={props.match.params.variant_options}
                  {...props}
                />
              )}
            />
            <Route path={`${RoutePaths.SHOP}`} exact component={ShopPage} />
            {/*
              DASHBOARD
              */}
            <Route
              path={RoutePaths.DASHBOARD}
              exact
              render={(props: RouteComponentProps<{}>) =>
                hasAccessToken ? <DashboardPage /> : <DashboardGuestPage />
              }
            />
            <Route
              path={`${RoutePaths.DASHBOARD}/${DashboardPaths.ORDERS}`}
              exact
              render={(props: RouteComponentProps<{}>) => (
                <GuestRedirect to={RoutePaths.DASHBOARD}>
                  <OrdersPage />
                </GuestRedirect>
              )}
            />
            <Route
              path={`${RoutePaths.DASHBOARD}/${DashboardPaths.PROFILE_EDIT}`}
              exact
              render={(props: RouteComponentProps<{}>) => (
                <GuestRedirect to={RoutePaths.DASHBOARD}>
                  <ProfileEditPage />
                </GuestRedirect>
              )}
            />
            <Route
              path={`${RoutePaths.DASHBOARD}/${DashboardPaths.FISHING_REPORTS}`}
              exact
              render={(props: RouteComponentProps<{}>) => (
                <GuestRedirect to={RoutePaths.DASHBOARD}>
                  <UserFishingReportsPage />
                </GuestRedirect>
              )}
            />
            <Route
              path={`${RoutePaths.DASHBOARD}/${DashboardPaths.EMAIL_PREFERENCES}`}
              exact
              render={(props: RouteComponentProps<{}>) => (
                <GuestRedirect to={RoutePaths.DASHBOARD}>
                  <UserEventNotificationsPage />
                </GuestRedirect>
              )}
            />
            <Route
              path={`${RoutePaths.DASHBOARD}/${DashboardPaths.GROUPS}`}
              exact
              render={(props: RouteComponentProps<{}>) => (
                <GuestRedirect to={RoutePaths.DASHBOARD}>
                  <DashboardGroupsPage />
                </GuestRedirect>
              )}
            />
            <Route
              path={`${RoutePaths.DASHBOARD}/${DashboardPaths.CHANGE_EMAIL}`}
              exact
              render={(props: RouteComponentProps<{}>) => (
                <GuestRedirect to={RoutePaths.DASHBOARD}>
                  <ChangeEmailPage />
                </GuestRedirect>
              )}
            />
            {/* May not be used */}
            {/* <Route
                path={`${RoutePaths.DASHBOARD}/${DashboardPaths.ACCOUNT}`}
                exact
                render={(props: RouteComponentProps<{}>) =>
                  hasAccessToken ? <AccountPage {...props} /> : <Redirect to={RoutePaths.HOME} />
                }
              /> */}
            {/* hiding until designed */}
            {/* <Route
                path={`${RoutePaths.DASHBOARD}/${DashboardPaths.PROFILE}`}
                exact
                render={(props: RouteComponentProps<{}>) =>
                  hasAccessToken ? <ProfilePage {...props} /> : <Redirect to={RoutePaths.HOME} />
                }
              /> */}
            <Route path={`${RoutePaths.ARTICLES}/:uid`} exact component={ArticlePage} />
            <Route path={RoutePaths.FISHING_ARTICLES} exact component={MediaPage} />
            <Route path={RoutePaths.PRO_MEDIA} exact component={ProMediaPage} />
            <Route path={RoutePaths.STATES} exact component={StatesPage} />
            <Route path={`${RoutePaths.STATES}/:slug`} exact component={StatePage} />
            <Route
              path={`${RoutePaths.WISHLIST}/:wishlist_hash`}
              render={(props: RouteComponentProps<{ wishlist_hash: string }>) => (
                <WishlistPage {...props} />
              )}
              exact
            />
            <Route
              path={`${RoutePaths.USERS}/:slug`}
              render={(props: RouteComponentProps<{ slug: string }>) => (
                <AmbassadorProfilePageContainer
                  {...props}
                  slug={props.match.params.slug}
                  key={props.match.params.slug}
                />
              )}
              exact
            />
            <Route
              path={`${RoutePaths.BRANDS}/:url_slug`}
              render={(props: RouteComponentProps<{ url_slug: string }>) => {
                const pageBrand = brands.find(
                  (brand) => brand.url_slug === props.match.params.url_slug
                );
                if (!pageBrand) {
                  return render404Page();
                }
                return <BrandPageContainer brand={pageBrand} {...props} />;
              }}
              exact
            />
            <Route path={`${RoutePaths.BRANDS}`} component={BrandsPage} exact />
            <Route path={`${RoutePaths.LISTS}/:url_slug`} component={ListPageContainer} exact />
            <Route
              path={`${RoutePaths.TOURNAMENTS}/:slug`}
              component={TournamentDetailPage}
              exact
            />
            <Route path={RoutePaths.TOURNAMENTS} component={TournamentsPage} exact />
            <Route path={RoutePaths.TOURNAMENT_SUBMIT} component={TournamentSubmitPage} exact />
            <Route path={RoutePaths.DEALS} component={DealsPage} exact />
            <Route path={`${RoutePaths.ORGS}/:slug`} component={OrgPage} />
            <Route path={RoutePaths.ORGS} component={OrgsPage} exact />
            <Route path={`${RoutePaths.LANDING_PAGES}/:slug`} component={LandingPagePage} exact />
            <Route path={RoutePaths.BEST_SELLERS} component={BestSellersPage} exact />
            <Route path={`${RoutePaths.NEWS}/:slug`} component={NewsDetailPage} />
            <Route path={RoutePaths.NEWS} component={NewsPage} exact />
            <Route path={`${RoutePaths.VIDEOS}/:uid`} component={VideoPage} exact />
            <Route path={RoutePaths.SALE} component={SalePage} exact />
            <Route path={RoutePaths.SALE_PRO} component={SaleProPage} exact />
            <Route path={RoutePaths.NEW} component={NewProductsPage} exact />
            <Route
              path={`${RoutePaths.FISHING_REPORTS}/:slug`}
              render={(props: RouteComponentProps<{ slug: string }>) => (
                <FishingReportPageContainer slug={props.match.params.slug} />
              )}
              exact
            />
            <Route
              path={`${RoutePaths.FISHING_REPORTS}`}
              component={FishingReportsPageContainer}
              exact
            />
            <Route
              path={`${RoutePaths.FISHING_REPORTS_WEEKLY}`}
              component={FishingReportWeeklyPage}
              exact
            />
            <Route path={RoutePaths.WISHLIST_GIVEAWAY} component={WishlistContestPage} exact />
            <Route
              path={`${RoutePaths.AMBASSADORS}/leaderboard`}
              component={AmbassadorsLeaderboardPage}
            />
            <Route path={RoutePaths.AMBASSADORS} component={AmbassadorsPage} />
            <Route
              path={`${RoutePaths.OMNIA_VIDEOS}/:slug/edit`}
              render={(props: RouteComponentProps<{ slug: string }>) => {
                return <OmniaVideoAdminPageContainer {...props} />;
              }}
            />
            <Route path={`${RoutePaths.OMNIA_VIDEOS}/:slug`} component={OmniaVideoPage} />
            <Route path={RoutePaths.CART_BINS} component={CartBinsPage} />
            <Route path={RoutePaths.CART_CLEAR} component={CartClearPage} />
            <Route
              path={`${RoutePaths.BEST_OF_YEAR}${BestOfPaths.LAKES}/:slug`}
              component={BestOfYearLakePageContainer}
            />
            <Route
              path={`${RoutePaths.BEST_OF_YEAR}${BestOfPaths.STATES}/:abbr`}
              component={BestOfYearStatePageContainer}
            />
            <Route path={RoutePaths.BEST_OF_YEAR} component={BestOfYearPage} exact />
            <Route path={`${RoutePaths.KITS}/:slug`} component={KitPageContainer} exact />
            {/* ========================================================================= //
              // AUTH PAGES
              // ========================================================================== */}
            <Route
              path={RoutePaths.LOGIN}
              exact
              component={LoginPage}
              // handles logged in redirect internally
            />
            <Route
              path={RoutePaths.SIGNUP}
              exact
              component={SignupPage}
              // handles logged in redirect internally
            />
            <Route
              path={RoutePaths.SIGNUP_FOLLOWUP}
              exact
              component={SignupFollowupPage}
              // handles logged in redirect internally
            />
            <Route
              path={`${RoutePaths.SIGNUP_GIVEAWAY}/:slug`}
              exact
              render={(props: RouteComponentProps<{ slug: string }>) => (
                <SignupGiveawayPage {...props} slug={props.match.params.slug} />
              )}
            />
            <Route
              path={RoutePaths.SIGNUP_GIVEAWAY_ENTERED}
              exact
              component={SignupGiveawayEnteredPage}
            />
            <Route
              path={RoutePaths.CONFIRM}
              exact
              render={(
                props: RouteComponentProps<{}, AppStaticRouterContext, { username: string }>
              ) => <ConfirmPage username={props.location.state?.username} {...props} />}
              // handles verified users redirect internally
            />
            <Route
              path={RoutePaths.PASSWORD_RESET}
              exact
              render={() =>
                hasAccessToken ? <Redirect to={RoutePaths.HOME} /> : <PasswordResetPage />
              }
            />
            <Route
              path={RoutePaths.PASSWORD_RESET_CONFIRM}
              exact
              render={(
                props: RouteComponentProps<{}, AppStaticRouterContext, { username: string }>
              ) =>
                hasAccessToken ? (
                  <Redirect to={RoutePaths.HOME} />
                ) : (
                  <PasswordResetConfirmPage username={props.location.state?.username} {...props} />
                )
              }
            />
            <Route path={RoutePaths.EMAIL_SENT} exact component={EmailSentPage} />
            {/* ========================================================================= //
              // STATIC PAGES
              // ========================================================================== */}
            <Route path={RoutePaths.ABOUT} exact component={AboutPage} />
            <Route path={RoutePaths.FAQS} exact component={FaqsPage} />
            <Route path={RoutePaths.WHO} exact component={WhoWeArePage} />
            <Route
              path={RoutePaths.AMBASSADOR_APPLICATION}
              exact
              component={AmbassadorApplicationPage}
            />
            <Route
              path={RoutePaths.AMBASSADOR_APPLICATION_FORM}
              exact
              component={AmbassadorApplicationFormPage}
            />
            <Route path={RoutePaths.TERMS} exact component={TermsPage} />
            <Route path={RoutePaths.PRIVACY} exact component={PrivacyPage} />
            <Route path={RoutePaths.DELIVERY_RETURN} exact component={ShippingReturnsPage} />
            <Route path={RoutePaths.JOBS} exact component={JobsPage} />
            <Route
              path={RoutePaths.FISHING_REPORTS_RULES}
              exact
              component={FishingReportsRulesPage}
            />
            <Route
              path={RoutePaths.BLACK_FRIDAY_CYBER_MONDAY}
              exact
              component={BlackFridayCyberMondayPage}
            />
            <Route path={RoutePaths.PERSONAL_SHOPPING} exact component={PersonalShoppingPage} />
            <Route path={RoutePaths.APP} exact component={AppPage} />
            <Route
              path={[`:url(${RoutePaths.GET_STARTED})`, `:url(${RoutePaths.GET_STARTED}.+)`]}
              render={(props: RouteComponentProps<{}>) => {
                const { speciesName, waterbodySlug } = splitGetStartedUrl(props.match.url);
                const specie = species.find(
                  (s) => s.display_name.toLowerCase().replace(' ', '-') === speciesName
                );
                if (specie && waterbodySlug) {
                  return (
                    <GetStartedPage
                      key={`${specie.name}${waterbodySlug}`}
                      species={specie}
                      waterbodySlug={waterbodySlug}
                    />
                  );
                }
                return <GetStartedUninitiatedPage waterbodySlug={waterbodySlug} />;
              }}
            />
            <Route path={RoutePaths.NEWSLETTER} exact component={NewsletterPage} />
            <Route path={RoutePaths.NEWSLETTER_SUCCESS} exact component={NewsletterPageSuccess} />
            <Route path={RoutePaths.JACK_LINKS_BONUS} exact component={JackLinksPage} />
            <Route path={RoutePaths.WILD_RIVER} exact component={WildRiverPage} />
            <Route
              path={RoutePaths.OMINA_BLUE_LANDING_PAGE}
              exact
              component={OmniaBlueLandingPage}
            />
            <Route path={RoutePaths.BIG_SELECTION} exact component={BigSelection} />
            <Route path={RoutePaths.SET_ALERTS} exact component={SetAlerts} />
            <Route path={RoutePaths.WE_SHIP_FAST} exact component={WeShipFast} />
            <Route path={RoutePaths.PREMIUM_PRO} exact component={ProPageContainer} />
            <Route path={RoutePaths.PRO_OFFER} exact component={ProOfferPage} />

            <Route
              path={RoutePaths.PREMIUM_PRO_PURCHASE_COMPLETE}
              exact
              component={OmniaProPurchaseCompletePage}
            />
            {/* For now PREMIUM_PRO_PURCHASE_COMPLETE and PREMIUM_PRO_TRIAL_PURCHASE_COMPLETE are handled by the same page */}
            <Route
              path={RoutePaths.PREMIUM_PRO_TRIAL_PURCHASE_COMPLETE}
              exact
              render={() => <OmniaProPurchaseCompletePage trial={true} />}
            />
            <Route path={RoutePaths.GO} exact component={GoPage} />
            <Route path={RoutePaths.ONE_LINK_RESOLVER} exact component={OneLinkResolver} />
            {/* ========================================================================= //
              // Redirects of old urls
              // ========================================================================== */}
            {/* Some unknown linking to /omniafishing should redirect home */}
            <Route exact path="/omniafishing">
              <Redirect to={RoutePaths.HOME} />
            </Route>
            <Route exact path="/account">
              <Redirect to={`${RoutePaths.DASHBOARD}/${DashboardPaths.ACCOUNT}`} />
            </Route>
            <Route exact path="/bassman">
              <Redirect to={`${RoutePaths.BRANDS}/bassman`} />
            </Route>
            {/* ========================================================================= //
              // 404
              // ========================================================================== */}
            <Route
              path="*"
              render={() => {
                return render404Page();
              }}
            />
          </Switch>
        </ErrorBoundary>
      </main>

      <CartContainer key={`CartContainer::${checkoutId}`} />

      <AffiliateLinkBuilder />
      <NewsletterPopup />
      <ScrollToTopButton />
      {showFooter && <Footer />}

      {/* global modals */}
      <InventoryModalContainer />
      <ProductFamilyQuickModalContainer />
      <FishingReportDisplayModalShower />
      <FishingReportModal />
      <SignupLoginModal />
      <ProUpsellModal />

      {/* non-rendering components */}
      <UserCartHandler />
      <UserCartAssociator />
      <UserCartAttributesHandler />
      <UserAuthHandlerContainer />
      <CartDiscountHandler />
      <CartProductAdder />
      <UserDataHandler />
      <GeographicLocationHandler />
      <ExperimentHandler />
      <WindowSizeContainer />
      <VersionChecker />
      <FlashNotification />
      <LocationChanger />
      <AffiliateChecker />
      <ShortUrlChecker />
      <BodyOverflowHandler />
      <MicrosoftClarityIntegration />
      <LineItemCustomAttributeFixer />
      <AppsFlyerSmartBannerResolver />
    </div>
  );
};
