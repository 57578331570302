import { StarFilled } from '@ant-design/icons';
import classNames from 'classnames';
import * as React from 'react';
import SvgLakes from '../svg/lakes';
import { IconAi } from './icon_ai';
import styles from './pins.less';
import { formatFishingReportsCount, PinProps } from './pin_low_activity';

export const PinHighActivity = (props: PinProps) => {
  const {
    className,
    favorite,
    featured,
    fishingReportCount = 0,
    hasSummary,
    onClick,
    selected,
  } = props;
  const style: React.CSSProperties = {};

  return (
    <div
      className={classNames(
        styles.pinWrapper,
        styles.pinWrapper__lg,
        {
          [styles.pinWrapper__selected]: selected,
        },
        className
      )}
      onClick={onClick}
      style={style}
    >
      <div
        className={classNames(styles.pin, styles.pin__lg, {
          [styles.pin__featured]: featured,
          [styles.pin__favorite]: favorite,
          [styles.pin__selected]: selected,
          [styles.pin__with_summary]: hasSummary,
        })}
        style={style}
      >
        {favorite ? (
          <StarFilled className={styles.svgStar} />
        ) : (
          <SvgLakes className={styles.svgLake} />
        )}
        {fishingReportCount > 0 && (
          <span className={styles.pinReportCount}>
            {formatFishingReportsCount(fishingReportCount)}
          </span>
        )}
      </div>
      {hasSummary && (
        <div className="bg-white top-[99%] absolute shadow rounded-l-[10px] rounded-r-[20px] pr-0.5 flex items-center w-[150%]">
          <span className="flex-grow-0 flex-shrink-0 -m-[1px] -mr-0">
            <IconAi />
          </span>
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#FF2A00] to-[#002BFF] text-xs font-medium">
            Insights
          </span>
        </div>
      )}
    </div>
  );
};

PinHighActivity.width = 48;
PinHighActivity.height = 48;
